import React from 'react';
import {Row, Col} from 'react-bootstrap';

import Article from '../../components/Articles/Article';

export default function ArticlesList({tile, header, articles, footer}) {
  const getArticlesChildren = () => {
    if (tile) {
      return (
        <Row className="justify-content-center">
          {articles.map((item) => (
            <Col key={`article_${item.id}`} md={4}>
              <Article tile={true} {...item} />
            </Col>
          ))}
        </Row>
      );
    }

    return articles.map((item) => (
      <Article key={`article_${item.id}`} {...item} />
    ));
  };

  return (
    <React.Fragment>
      {header}

      {articles.length ? (
        getArticlesChildren()
      ) : (
        <h1>No News Articles, come back later.</h1>
      )}

      {footer}
    </React.Fragment>
  );
}
