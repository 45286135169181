import React from 'react';

import './Footer.css';

export default function Footer({
  footer,
  logo_image,
  community_name,
  toggleModal
}) {
  let footerNavJSX = footer.nav_list.map((item) => {
    return (
      <li key={item.id}>
        <a href={item.link}>{item.title}</a>
      </li>
    );
  });

  return (
    <footer className="page-footer d-flex">
      <div className="container my-5">
        {logo_image && (
          <div className="d-flex justify-content-center mb-4">
            <a href={footer.urls.home}>
              <img className="community-icon" src={logo_image} />
            </a>
          </div>
        )}

        <div className="d-flex justify-content-center mb-3">
          <ul className="footer-list d-flex justify-content-center flex-wrap list-unstyled mb-0">
            {footerNavJSX}
            <li>
              <a
                href="#"
                onClick={toggleModal}
                className="contact_trigger contact_link"
              >
                Contact Us
              </a>
            </li>

            <li>
              <a
                href="https://support.drund.com/hc/en-us"
                target="_blank"
                rel="noreferrer"
              >
                Support
              </a>
            </li>
          </ul>
        </div>
        <div className="d-flex justify-content-center">
          <div className="body3 copyright">
            Copyright {new Date().getFullYear()} {community_name}. All rights
            reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
