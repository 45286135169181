import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import {Row, Col} from 'react-bootstrap';

import './Article.css';
import {getFormatUTCTime} from '../../../../helpers/timestamps';

import ArticleModal from '../../components/Articles/ArticleModal';
import {useModal} from '../../../../hooks/useModal';

// Updates Ellipsis on resize
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export default function Article({
  author,
  source,
  created,
  title,
  image,
  body,
  featured_image,
  relative_path,
  show_author,
  tile
}) {
  const {modalOpen, toggleModal} = useModal();

  const handleClick = (e) => {
    // Bail if no source
    if (!source) {
      return;
    }

    var workingList = [
      'NCAA',
      'The Lantern',
      'Ohio State Buckeyes',
      'Buckeye Wire',
      'Lettermen Row',
      'Cleveland'
    ];
    // var notWorking = [
    //   'ESPN',
    //   'Eleven Warriors',
    //   'Buckeye Scoop',
    //   'Rivals',
    //   'Bucknuts' // Bucknuts behaves weird so its broken
    // ];

    if (workingList.includes(source.name)) {
      e.preventDefault();
      toggleModal(true);
    }
  };

  const getArticleBody = () => {
    if (tile) {
      return (
        <div>
          {image && (
            <div
              className="blog-list-image aspect16x9 mb-3"
              style={{backgroundImage: `url(${image})`}}
            ></div>
          )}
          <div className="">
            <ResponsiveEllipsis
              text={body}
              maxLine="5"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </div>
        </div>
      );
    }

    return (
      <Row className="no-gutters">
        {image && (
          <Col lg={5} xl={4} className="pr-lg-0">
            <div
              className="blog-list-image aspect16x9"
              style={{backgroundImage: `url(${image})`}}
            ></div>
          </Col>
        )}
        <Col
          lg={source?.image || featured_image ? 7 : false}
          xl={source?.image || featured_image ? 8 : false}
          className={
            source?.image || featured_image ? 'pl-lg-3 pt-3 pt-lg-0' : false
          }
        >
          <ResponsiveEllipsis
            text={body}
            maxLine="5"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </Col>
      </Row>
    );
  };

  return (
    <React.Fragment>
      <div className="preview_article p-0 mb-5">
        {source ? (
          <a
            className="overline font-weight-normal"
            href={source.url}
            target="_blank"
            rel="noreferrer"
          >
            {source.name}
          </a>
        ) : (
          show_author && <div className="overline">{author.display_name}</div>
        )}
        <a
          className="preview_article_link d-block"
          href={source ? source.url : relative_path}
          target={source && '_blank'}
          rel={source && 'noreferrer'}
          data-url={source && source.url}
          data-title={title}
          data-name={source && source.name}
          onClick={handleClick}
        >
          <div className="title-link h2 mb-0">{title}</div>

          <div className="body3 mid-grey timestamp posted mb-3">
            {getFormatUTCTime(created, 'MMMM D, YYYY [at] h:mm A')}
          </div>

          {getArticleBody()}
        </a>
      </div>

      {source && (
        <ArticleModal
          open={modalOpen}
          onClose={() => toggleModal(false)}
          title="Go To News Article"
          iframeUrl={source.url}
        />
      )}
    </React.Fragment>
  );
}
