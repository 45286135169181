import React from 'react';

import './Contributor.css';

export default function Contributor({
  title,
  display_name,
  path,
  info,
  avatar,
  divider,
  show_info
}) {
  return (
    <React.Fragment>
      <div
        className={`contributor d-flex align-items-center pt-2 ${
          divider ? 'border-bottom contributor_list_spacing' : ''
        }`}
      >
        <a className="mr-3" href={path}>
          <img className="avatar avatar64x64" src={avatar} />
        </a>
        <div className="overflow-hidden">
          <a className="contributor_name body1 d-block ellipsis" href={path}>
            {display_name}
          </a>
          <div className="contributor_title body3 mid-grey mt-1 ellipsis">
            {title}
          </div>

          {show_info && info && (
            <div className="description mid-grey mt-2">{info}</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
