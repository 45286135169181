import React from 'react';
import {createRoot} from 'react-dom/client';
import {Container, Row, Col} from 'react-bootstrap';

import BasePage from '../Base/Base';

import Playlist from '../../components/Playlist/Playlist.js';
import ScheduledPlaylist from '../../components/ScheduledPlaylist/ScheduledPlaylist.js';
import ArticlesList from '../../components/Articles/ArticlesList';
import ContributorsList from '../../components/Contributors/ContributorsList';
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import AdSense from 'react-adsense';

function BlogPlaylistCombo(props) {
  return (
    <BasePage {...props}>
      <Container id="container" className="pt-4 pb-5">
        {props.playlist &&
          props.display_video_player &&
          (props.playlist?.scheduled ? (
            <ScheduledPlaylist playlistData={props.playlist} />
          ) : (
            <Playlist playlistData={props.playlist} />
          ))}

        <Row id="col1" className="justify-content-center">
          <Col lg={8} className="pr-lg-5">
            <ArticlesList
              header={<h5 className="mb-3 text-uppercase">Latest News</h5>}
              articles={props.articles}
              footer={
                <a
                  href={props.articles_path}
                  className="h3 all-content-link m-0"
                >
                  See All Latest News
                  <svg
                    viewBox="0 0 32 32"
                    className="svg-icon svg-icon-24x24 icon-ic_chevron_right all-content-link-icon"
                  >
                    <use xlinkHref="#icon-ic_chevron_right"></use>
                  </svg>
                </a>
              }
            />
          </Col>
          <Col
            lg={4}
            className="mt-5 mt-lg-0 mb-5 mb-lg-0 p-lg-0 d-flex flex-column"
          >
            {props.display_contributors_list && props.contributors && (
              <ContributorsList
                header={
                  <h5 className="mb-2 text-uppercase">Contributing Staff</h5>
                }
                contributors={props.contributors}
                footer={
                  <Row className="row no-gutters mb-5 mt-3">
                    <a
                      href={props.contributors_path}
                      className="h4 all-content-link m-0"
                    >
                      See All Contributors
                      <svg
                        viewBox="0 0 32 32"
                        className="svg-icon svg-icon-24x24 icon-ic_chevron_right all-content-link-icon"
                      >
                        <use xlinkHref="#icon-ic_chevron_right"></use>
                      </svg>
                    </a>
                  </Row>
                }
              />
            )}

            {props.twitter_timeline_url && (
              <TwitterTimelineEmbed
                onLoad={function noRefCheck() {}}
                autoHeight={true}
                url={props.twitter_timeline_url}
                sourceType="url"
              />
            )}

            {props.google_ads && Object.keys(props.google_ads).length > 0 && (
              <div className={props.twitter_timeline_url ? 'mt-5' : ''}>
                <AdSense.Google
                  client={props.google_ads.data_ad_client}
                  slot={props.google_ads.data_ad_slot}
                  style={{display: 'block'}}
                  format={props.google_ads.data_ad_format}
                  responsive="true"
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </BasePage>
  );
}

createRoot(document.getElementById('custom_page_container')).render(
  React.createElement(BlogPlaylistCombo, window.props)
);
