import {useRef, useState} from 'react';

export const usePlaylist = (playlistData) => {
  const [playlist, setPlaylist] = useState(playlistData);
  const infoRef = useRef(null);

  let changeVideo = (selectedVideo) => {
    // push the current video back into the list
    setPlaylist({
      ...playlist,
      video: selectedVideo
    });
  };

  let goToNext = () => {
    let index = playlist.videos.findIndex(
      (element) => element.id == playlist.video.id
    );

    if (index >= playlist.videos.length - 1) {
      // wrap back to the start
      changeVideo(playlist.videos[0]);
    } else {
      // go to next video
      changeVideo(playlist.videos[index + 1]);
    }
  };

  let toggleInfo = () => {
    infoRef.current?.classList.toggle('d-none');
  };

  return {
    playlist,
    changeVideo,
    goToNext,
    infoRef,
    toggleInfo
  };
};
