import React from 'react';
import Contributor from './Contributor';

export default function ContributorsList({header, contributors, footer}) {
  return (
    <React.Fragment>
      {header}

      {contributors.length ? (
        contributors.map((item) => (
          <Contributor key={`contributor_${item.id}`} {...item} />
        ))
      ) : (
        <h1>No Contributors</h1>
      )}

      {footer}
    </React.Fragment>
  );
}
