import moment from 'moment';

export const getFormatUTCTime = (
  timestamp,
  format = 'M/D/Y [at] h:mm a',
  inputFormat
) => {
  return moment.utc(timestamp, inputFormat).local().format(format);
};

// converts durations to seconds
// example: '0:04' -> 4     '1:34' -> 94
export const DurationToSeconds = (duration) => {
  if (!duration) {
    return;
  }

  var times = duration.split(':');
  if (times.length == 3) {
    return (
      parseInt(times[0]) * 60 * 60 +
      parseInt(times[1]) * 60 +
      parseInt(times[2])
    );
  } else if (times.length == 2) {
    return parseInt(times[0]) * 60 + parseInt(times[1]);
  }
  return;
};

// takes seconds and returns human readable countdown message
export const HumanizeCountdown = (seconds = 0) => {
  return moment.duration(seconds * 1000).humanize();
};
