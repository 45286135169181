import {useRef, useEffect, useState} from 'react';

import {
  DurationToSeconds,
  HumanizeCountdown
} from '../../../../helpers/timestamps';

export const useScheduledPlaylist = (playlistData) => {
  // initialize pretty_countdown instead of waiting for countdown to start
  playlistData.scheduled.pretty_countdown = HumanizeCountdown(
    playlistData.scheduled?.countdown
  );

  const countersRef = useRef({
    countdown: playlistData.scheduled.is_playing
      ? DurationToSeconds(playlistData.video.duration) -
        playlistData.scheduled.video_start
      : playlistData.scheduled?.countdown,
    currentTime: playlistData.scheduled.is_playing
      ? playlistData.scheduled.video_start
      : 0
  });

  const [playlist, setPlaylist] = useState(playlistData);

  const infoRef = useRef(null);

  let goToNext = () => {
    if (playlist.videos.length == 0) {
      // playlist complete
      setPlaylist({
        scheduled: {
          ...playlist.scheduled,
          is_playing: false,
          is_complete: true
        },
        video: {},
        videos: []
      });
      countersRef.current.countdown = 86400; // 1 day in seconds
    } else {
      countersRef.current.currentTime = 0;
      countersRef.current.countdown = DurationToSeconds(
        playlist.videos[0].duration
      );
      // go to next video
      setPlaylist({
        scheduled: {
          ...playlist.scheduled,
          is_playing: true
        },
        video: playlist.videos[0],
        videos: playlist.videos.slice(1)
      });
    }
  };

  let toggleInfo = () => {
    infoRef.current?.classList.toggle('d-none');
  };

  let onSeek = (data) => {
    let player = data.target.player;

    if (player) {
      if (countersRef.current.currentTime < player.currentTime()) {
        player.currentTime(countersRef.current.currentTime);
      }
    }
  };

  let updateCountdownMessage = () => {
    if (!playlist.scheduled.is_playing) {
      let countdownMessage = HumanizeCountdown(countersRef.current?.countdown);

      // only rerender if new message, could replace with useMemo I believe
      if (playlist.scheduled.pretty_countdown != countdownMessage) {
        setPlaylist({
          ...playlist,
          scheduled: {
            ...playlist.scheduled,
            pretty_countdown: countdownMessage
          }
        });
      }
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (countersRef.current.countdown == 0) {
        goToNext();
      } else {
        countersRef.current.currentTime += 1;
        countersRef.current.countdown -= 1;

        updateCountdownMessage();
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  return {
    playlist,
    goToNext,
    onSeek,
    currentTime: countersRef.current.currentTime,
    infoRef,
    toggleInfo
  };
};
