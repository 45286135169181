import React, {useState} from 'react';

export default function NavItem({item}) {
  let [navItem, setNavItem] = useState(item);

  let subPageJsx;
  if (item.sub_pages) {
    subPageJsx = item.sub_pages.map((subpageItem) => {
      return (
        <li key={subpageItem.id}>
          <a
            className="subnav_item subnav_padding body d-block ellipsis"
            href={subpageItem.link}
          >
            {subpageItem.title}
          </a>
        </li>
      );
    });
  }

  return (
    <li
      className={
        'nav-item position-relative m-0' +
        (navItem.sub_pages ? ' sub_nav_trigger' : '') +
        (navItem.current_page ? ' active' : '') +
        (navItem.sub_pages_open ? ' open' : '')
      }
    >
      <span className="h-100 d-flex align-items-center justify-content-between">
        <a
          className="px-lg-3 py-lg-0 h-100 h4 mb-0 d-flex align-items-center nav-link flex-grow-1"
          href={navItem.link}
        >
          <span className="ellipsis">{navItem.title}</span>
        </a>
        {navItem.sub_pages && (
          <a
            href="#"
            onClick={() =>
              setNavItem({...navItem, sub_pages_open: !navItem.sub_pages_open})
            }
            className={
              'dropdown-arrow flex-shrink-0 p-2' +
              (navItem.sub_pages_open ? ' trigger_rotate' : '')
            }
          >
            <svg
              viewBox="0 0 32 32"
              className="rotate svg-icon svg-icon-24x24 icon-ic_chevron_right svg-icon-gray flex-shrink-0"
            >
              <use href="#icon-ic_chevron_right"></use>
            </svg>
          </a>
        )}
      </span>
      {navItem.sub_pages && (
        <menu className="subnav p-0 m-0 w-100">{subPageJsx}</menu>
      )}
    </li>
  );
}
