import React, {useState} from 'react';
import useResize from '../../../../hooks/useResize.js';

export default function UserDropdown({membership}) {
  let [dropdownOpen, setDropdownOpen] = useState(false);

  // close dropdown on resize
  useResize(() => {
    setDropdownOpen(false);
  });

  return (
    <div
      className={
        'profile-nav position-relative h-100 ml-auto' +
        (dropdownOpen && ' active')
      }
    >
      <div
        onClick={() => {
          setDropdownOpen(!dropdownOpen);
        }}
        className="h-100 h4 px-3 m-0 d-flex align-items-center"
      >
        <svg
          viewBox="0 0 32 32"
          className="rotate mr-2 svg-icon svg-icon-24x24 icon-ic_chevron_down"
        >
          <use href="#icon-ic_chevron_down"></use>
        </svg>
        <li className="nav-link-profile">{membership.display_name}</li>
      </div>
      <menu className="subnav profile_subnav">
        <a
          className="subnav_profile profile_subnav_item subnav_padding subnav_border body d-flex"
          href="#"
        >
          <img
            src={membership.avatar}
            className="profile-image rounded-circle"
            width="48"
            height="48"
          />
          <div>
            <h4 className="mb-0">{membership.display_name}</h4>
            <div className="profile-title ellipsis">{membership.title}</div>
          </div>
        </a>
        <a
          className="profile_subnav_item subnav_padding body d-flex align-items-center"
          target="_blank"
          rel="noreferrer"
          href="https://support.drund.com/"
        >
          <svg
            viewBox="0 0 32 32"
            className="mr-3 svg-icon-dark-gray svg-icon svg-icon-24x24 icon-ic_faq"
          >
            <use href="#icon-ic_faq"></use>
          </svg>
          FAQ
        </a>
        <a
          className="profile_subnav_item subnav_padding body d-flex align-items-center"
          href={membership.urls.settings}
        >
          <svg
            viewBox="0 0 32 32"
            className="mr-3 svg-icon-dark-gray svg-icon svg-icon-24x24 icon-ic_settings"
          >
            <use href="#icon-ic_settings"></use>
          </svg>
          Settings
        </a>
        {membership.has_admin_perms && (
          <a
            className="profile_subnav_item subnav_padding subnav_border body d-flex align-items-center"
            href={membership.urls.admin}
          >
            <svg
              viewBox="0 0 32 32"
              className="mr-3 svg-icon-dark-gray svg-icon svg-icon-24x24 icon-ic_admin"
            >
              <use href="#icon-ic_admin"></use>
            </svg>
            Admin
          </a>
        )}
        <a
          className="profile_subnav_item subnav_padding body d-flex align-items-center"
          href={membership.urls.logout}
        >
          <svg
            viewBox="0 0 32 32"
            className="mr-3 svg-icon-dark-gray svg-icon svg-icon-24x24 icon-ic_logout"
          >
            <use href="#icon-ic_logout"></use>
          </svg>
          Log Out
        </a>
      </menu>
    </div>
  );
}
