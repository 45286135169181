import React from 'react';

export default function PlaylistVideo({video, clickCallback, active}) {
  return (
    <a
      className={
        'playlist-element d-flex flex-column flex-lg-row flex-shrink-0 pb-2 mr-3 m-lg-0' +
        (active ? ' active' : '')
      }
      style={{minWidth: '200px'}}
      href="#"
      onClick={() => clickCallback(video)}
    >
      <div className="playlist-element-image col-lg-5 p-0">
        <div
          className="playlist-image aspect16x9 position-relative"
          style={{backgroundImage: `url(${video.thumbnail})`}}
        ></div>
        <h4 className="playing-text">Playing</h4>
      </div>
      <div className="col-lg-7 p-0 d-flex mt-2 m-lg-0">
        <div>
          <h5 className="playlist-title mb-0 pb-1">{video.title}</h5>
          <div className="playlist-duration body3 mid-grey">
            {video.duration}
          </div>
        </div>
      </div>
    </a>
  );
}
