import React, {useState} from 'react';

import './Header.css';
import UserDropdown from '../UserDropdown/UserDropdown.js';
import useResize from '../../../../hooks/useResize.js';
import NavItem from './NavItem.js';
import SentryTest from '../../../../components/SentryTest/SentryTest.js';

export default function Header({nav, logo_image, membership, environment}) {
  let [dropdownOpen, setDropdownOpen] = useState(false);
  useResize(() => setDropdownOpen(false));

  let navItemsJsx = nav.pages.map((navItem) => {
    return <NavItem key={navItem.id} item={navItem} />;
  });

  if (environment.toLowerCase().trim() == 'development') {
    navItemsJsx.push(<SentryTest key={-1} />);
  }

  return (
    <div className="navbar">
      <div className="container h-100">
        <nav className="custom-greedy greedy d-flex flex-grow-1 h-100">
          <button
            onClick={() => setDropdownOpen(!dropdownOpen)}
            className={'nav-button' + (dropdownOpen ? ' active' : '')}
          >
            <svg
              viewBox="0 0 32 32"
              className="rotate svg-icon svg-icon-24x24 icon-ic_menu flex-shrink-0"
            >
              <use href="#icon-ic_menu"></use>
            </svg>
          </button>

          {logo_image && (
            <a
              href={nav.urls.home}
              className="d-flex align-items-center mx-2 p-lg-0"
            >
              <img className="community-icon" src={logo_image} />
            </a>
          )}

          <ul className="links d-flex flex-grow-1 m-0 pl-0 pr-lg-3 h-100">
            {navItemsJsx}
          </ul>
          <ul
            className={'hidden-links' + (!dropdownOpen ? ' hidden' : '')}
          ></ul>
          {membership.display_name ? (
            <UserDropdown membership={membership} />
          ) : (
            <ul className="ml-auto h-100 d-flex align-items-center m-0">
              <li className="nav-item m-0 h-100">
                <a
                  className="nav-link h-100 d-flex align-items-center h5 font-weight-normal mb-0 px-2 px-md-3"
                  href="/register/"
                >
                  Register
                </a>
              </li>
              <li className="nav-item m-0 h-100">
                <a
                  className="nav-link h-100 d-flex align-items-center h4 mb-0 px-2 px-md-3"
                  href="/login/"
                >
                  <svg
                    viewBox="0 0 32 32"
                    className="mr-md-2 svg-icon-white svg-icon svg-icon-24x24 icon-ic_account"
                  >
                    <use href="#icon-ic_account"></use>
                  </svg>
                  <span className="d-none d-md-inline-block">Login</span>
                </a>
              </li>
            </ul>
          )}
        </nav>
      </div>
    </div>
  );
}
