import React from 'react';
import ReactDom from 'react-dom';

import '../../../../styles/modals/modal.css';
import './ArticleModal.css';

export default function Modal({open, color, title, onClose, iframeUrl}) {
  if (!open) return null;

  return ReactDom.createPortal(
    <React.Fragment>
      <div className={'modal_overlay article_overlay ' + color}>
        <div className="modal modal-shadow article_modal flex-grow-1">
          <header className="modal_header modal_wrapper p-3 m-0 d-flex align-items-center justify-content-between">
            <a
              href="#"
              target="_blank"
              className="article_modal_link modal_header_text h3 m-0"
            >
              {title}
            </a>
            <a href="#" onClick={onClose} className="close">
              <svg
                viewBox="0 0 32 32"
                className="svg-icon svg-icon-24x24 svg-icon-dark-gray icon-ic_close "
              >
                <use href="#icon-ic_close"></use>
              </svg>
            </a>
          </header>
          <section className="modal_wrapper modal_content d-flex h-100">
            <iframe
              id="article_iframe"
              src={iframeUrl}
              className="flex-grow-1"
            ></iframe>
          </section>
        </div>
      </div>
    </React.Fragment>,
    document.getElementById('modal-root')
  );
}
