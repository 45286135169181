import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

import './drund-video-js.css';

// example hook implementation from https://docs.videojs.com/tutorial-react.html

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const {options, onReady, onClearReady, currentTime} = props;

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        if (onReady) {
          onReady(player);
        }
      }));

      if (currentTime) player.currentTime(currentTime);
    } else {
      // you can update player here [update player through props]
      const player = playerRef.current;
      player.src(options.sources);
      player.poster(options.poster);

      // rebind onReady function
      if (onReady) {
        onReady(player);
      }
    }

    return () => {
      const player = playerRef.current;
      // clear on ready events
      if (onClearReady) {
        onClearReady(player);
      }
    };
  }, [options, videoRef, onReady, onClearReady, currentTime]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className="stream_video">
      <div className="stream_video_wrapper">
        <div data-vjs-player>
          <video ref={videoRef} className="video-js vjs-big-play-centered" />
        </div>
      </div>
    </div>
  );
};

export default VideoJS;
