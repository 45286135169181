import React, {useRef} from 'react';
import {Row, Col} from 'react-bootstrap';

import './Playlist.css';

import VideoJS from '../Videos/VideoJS.js';
import {getFormatUTCTime} from '../../../../helpers/timestamps';

import PlaylistVideo from './PlaylistVideo.js';
import {usePlaylist} from './usePlaylist.js';

export default function Playlist({playlistData}) {
  const {playlist, changeVideo, goToNext, infoRef, toggleInfo} =
    usePlaylist(playlistData);
  const playerRef = useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fill: true,
    sources: [
      {
        src: playlist.video.url,
        type: playlist.video.url_type
      }
    ]
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on('ended', goToNext);
    player.on('play', toggleInfo);
    player.on('pause', toggleInfo);
  };

  const clearPlayerReady = (player) => {
    player.off('ended', goToNext);
    player.off('play', toggleInfo);
    player.off('pause', toggleInfo);
  };

  const videosJsx = playlist.videos.map((video) => {
    return (
      <PlaylistVideo
        key={video.id}
        video={video}
        clickCallback={changeVideo}
        active={video.id == playlist.video.id}
      />
    );
  });

  return (
    <Col className="mb-5">
      <div className="video-area">
        <Row className="video-area-background d-flex">
          <Col lg={8} className="video-area-left mb-3 m-lg-0 p-0 pr-lg-3">
            <div className="video-player-area stream position-relative">
              <VideoJS
                options={videoJsOptions}
                onReady={handlePlayerReady}
                onClearReady={clearPlayerReady}
                onComplete={goToNext}
              />

              {playlist.video.source?.name && (
                <a
                  id="source_area"
                  className="video-source-area p-2"
                  href={playlist.video.source.link}
                  rel="noreferrer"
                  target="_blank"
                >
                  <svg
                    viewBox="0 0 32 32"
                    className="svg-icon svg-icon-12x12 icon-ic_open_in_new svg-icon-source-link svg-icon-white mr-2"
                  >
                    <use href="#icon-ic_open_in_new"></use>
                  </svg>
                  <span id="source_name" className="source_link">
                    {playlist.video.source.name}
                  </span>
                </a>
              )}

              <div
                className="video-details-area video-details-area-above-bar p-3 w-100"
                ref={infoRef}
              >
                <div className="video-details mb-1">
                  <React.Fragment>
                    <h5 className="video-title ellipsis m-0 font-weight-bold">
                      {playlist.video.title}
                    </h5>
                    <h5 className="video-description m-0 d-none">
                      {playlist.video.description}
                    </h5>
                  </React.Fragment>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="video-duration text-secondary-color description mb-0">
                    {playlist.video.duration}
                    {playlist.video.duration && playlist.video.timestamp && (
                      <React.Fragment> &bull;&nbsp;</React.Fragment>
                    )}
                  </div>
                  <div className="video-created text-secondary-color description mb-0 posted">
                    {getFormatUTCTime(playlist.video.timestamp, 'MMM. D, YYYY')}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} className="p-0 d-flex flex-column">
            {videosJsx.length > 0 ? (
              <div className="flex-grow-1 playlist-container d-flex flex-row flex-lg-column">
                {videosJsx}
              </div>
            ) : (
              <h3>No upcoming videos.</h3>
            )}
          </Col>
        </Row>
      </div>
    </Col>
  );
}
